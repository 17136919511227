import React, { useState } from 'react'

import { Link, navigate } from 'gatsby'

import Snackbar from '@material-ui/core/Snackbar'

import LayoutAuth from '../../components/layout-auth'

import { Formik, Form } from 'formik'
import { object, string } from 'yup'

import { TextField, InputAdornment } from '@material-ui/core'
import { StylesProvider } from '@material-ui/core/styles'

import { useAlert, Alert } from '../../hooks/alert'
import { confirmForgotPassword } from '../../services/auth'

import classNames from 'classnames'

import {
  AuthContainer,
  formContainer,
  passwordToggle,
  title,
  subtitle,
  fields,
  field,
  fieldError,
  formLink,
  linkLogin,
  submitConfirm,
} from './auth.module.scss'
import './material-overrides.scss'

const schema = object({
  // passedEmail isn't displayed; it's used to conditionally apply validation to email if
  passedEmail: string(),
  email: string().when('passedEmail', {
    is: (val) => !!!val, // Return true if val is falsy
    then: string().email().required(),
  }),
  verificationCode: string().required('A valid verification code is required'),
  password: string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(
      /[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/,
      'Password must contain at least one special character'
    ),
  // TODO: Figure out a way to get the user's pet's name
  //.notOneOf([name], "Password must not be your pet's name"),
})

const ForgotPasswordConfirmation = ({ location }) => {
  const [showPassword, setShowPassword] = useState(false)

  const [alertOpen, alertMessage, openAlert, closeAlert] = useAlert()

  const handleAlertClose = (event, reason) => closeAlert(reason)

  const { state } = location
  let email = ''

  if (state && state.email) {
    email = state.email
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      await confirmForgotPassword(values)

      // TODO
      navigate('/app/profile')
    } catch (err) {
      openAlert(err.message)
    }
  }

  return (
    <LayoutAuth>
      <div className={AuthContainer}>
        <div className={formContainer}>
          <Formik
            initialValues={{
              passedEmail: email,
              email,
              verificationCode: '',
              password: '',
            }}
            validationSchema={schema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <Form>
                <span className={title}>Choose your password</span>
                <span className={subtitle}>
                  Enter your e-mail, the verification code you received and your
                  new password
                </span>
                <div className={fields}>
                  <StylesProvider injectFirst>
                    <TextField
                      name="email"
                      variant="filled"
                      className={classNames({
                        [field]:
                          !touched.email || (touched.email && !!!errors.email),
                        [fieldError]: touched.email && errors.email,
                      })}
                      fullWidth
                      label="Your Email"
                      type="text"
                      placeholder="Enter email"
                      defaultValue={email}
                      disabled={!!email}
                      helperText={touched.email ? errors.email : ''}
                      error={touched.email && Boolean(errors.email)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      name="verificationCode"
                      variant="filled"
                      className={classNames({
                        [field]:
                          !touched.verificationCode ||
                          (touched.verificationCode &&
                            !!!errors.verificationCode),
                        [fieldError]:
                          touched.verificationCode && errors.verificationCode,
                      })}
                      fullWidth
                      label="Your Verification Code"
                      type="text"
                      placeholder="Enter verification code"
                      helperText={
                        touched.verificationCode ? errors.verificationCode : ''
                      }
                      error={
                        touched.verificationCode &&
                        Boolean(errors.verificationCode)
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      name="password"
                      variant="filled"
                      className={classNames({
                        [field]:
                          !touched.password ||
                          (touched.password && !!!errors.password),
                        [fieldError]: touched.password && errors.password,
                      })}
                      fullWidth
                      label="Your Password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter password"
                      helperText={touched.password ? errors.password : ''}
                      error={touched.password && Boolean(errors.password)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        // Show password toggle
                        endAdornment: (
                          <InputAdornment position="end">
                            <span
                              className={passwordToggle}
                              onClick={handleShowPassword}
                              onKeyPress={handleShowPassword}
                              tabIndex="0"
                              role="button"
                            >
                              {showPassword ? 'Hide' : 'Show'}
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </StylesProvider>
                </div>
                <div className={linkLogin}>
                  <span className={formLink}>
                    <Link to="/auth/forgot-password">Forgot password</Link>
                  </span>
                </div>
                <div className={submitConfirm}>
                  <button type="submit" aria-disabled={isSubmitting}>
                    Change Password
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertOpen}
        message={alertMessage}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </LayoutAuth>
  )
}

export default ForgotPasswordConfirmation
